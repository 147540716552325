import React, { useState } from 'react';
import { HomeFilled } from '@ant-design/icons';
import { Typography, Input, Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageSummary } from '../../../../components/layout/PageSummary';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { ContentButton, Divider } from './styled';
import { Button } from '../../../../components/shered/Button';
import api from '../../../../services/api';
import { cnpjClear } from '../../../../utils/cpnjClear';

const { Search } = Input;
const { Title } = Typography;

const breadcrumb = [
  {
    label: <HomeFilled />,
    link: '/dashboard',
  },
  {
    label: 'Empreendimentos',
    link: '/enterprise',
  },
  {
    label: 'Novo Empreendimento',
  },
];

export function EnterpriseCreate({ title }: TitleProps) {
  useDocumentTitle(title);
  const [enterprise, setEnterprise] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onSearch = async (value: string) => {
    setLoading(true);
    setErrorMessage(null);
    const cnpj = cnpjClear(value);
    try {
      const res = await api.post(`/enterprise/${cnpj}`);
      if (res.status === 200) {
        setEnterprise(res.data);
        toast.success('Cadastro do empreendimento concluído com êxito.');
      } else if (res.status === 404) {
        setErrorMessage(
          'O CNPJ informado não corresponde a um empreendimento no CUCA'
        );
      } else if (res.data.message?.includes('CUCA')) {
        toast.error('Erro ao buscar dados na API CUCA');
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      setErrorMessage(
        'O CNPJ informado não corresponde a um empreendimento no CUCA'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <LayoutAppCMS>
      <PageSummary
        pageTitle="Novo empreendimento"
        itemBreadcrumb={breadcrumb}
      />
      <Divider>
        <Title level={5}>Informe o CPF/CNPJ</Title>
        <Search
          size="large"
          placeholder="Informe o CPF/CNPJ"
          allowClear
          loading={loading}
          onSearch={onSearch}
          style={{ width: 304 }}
          enterButton
        />
        {errorMessage && (
          <Typography.Text type="danger">{errorMessage}</Typography.Text>
        )}
      </Divider>
      {enterprise && (
        <>
          <Descriptions
            size="middle"
            column={4}
            title={<Title level={4}>Dados do empreendimento</Title>}
            layout="vertical"
          >
            <Descriptions.Item label={<Title level={5}>Nome</Title>}>
              {enterprise.enterprise_name}
            </Descriptions.Item>
            <Descriptions.Item label={<Title level={5}>CNPJ</Title>}>
              {enterprise.cpf_cnpj}
            </Descriptions.Item>
            <Descriptions.Item label={<Title level={5}>Cidade</Title>}>
              {enterprise.city}
            </Descriptions.Item>
          </Descriptions>
          <ContentButton>
            <Link to="/enterprise">
              <Button href="/enterprise" type="default">
                Voltar
              </Button>
            </Link>
          </ContentButton>
        </>
      )}
    </LayoutAppCMS>
  );
}
