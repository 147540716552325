import querystring from 'querystring';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Spin, Table, Modal } from 'antd';
import { toast } from 'react-toastify';
import { CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import Logo from '../../../assets/png/logo_IEMA.png';
import { Content, ContentTable } from './styled';
import LayoutAuthSeparations from '../../../layouts/auth/Separations';
import api from '../../../services/api';
import ButtonSignInGov from '../../../components/layout/ButtonGov';
import { Drawer } from '../../../components/shered/Drawer';
import { ListAccreditation } from '../../app/laboratory/listAccreditation';
import { IPropAcreditation, IPropsTemplate } from './types';
import { columnsLaboratory, columnsTemplate, data } from './utils';

const Login = () => {
  const [templateList, setTemplateList] = useState<IPropsTemplate[]>([]);
  const [laboratoryList, setLaboratoryList] = useState<IPropAcreditation[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [accreditation, setAcreditation] = useState<any>();
  const [isModalTemplateOpen, setIsModalTemplateOpen] = useState(false);
  const [isModalLaboratoryOpen, setIsModalLaboratoryOpen] = useState(false);
  const [urlGov, setUrlGov] = useState<string>('');
  const { search } = useLocation();

  const showTemplateModal = () => {
    setIsModalTemplateOpen(true);
  };

  const handleCancelTemplateModal = () => {
    setIsModalTemplateOpen(false);
  };

  const showLaboratoryModal = () => {
    setIsModalLaboratoryOpen(true);
  };

  const handleCancelLaboratoryModal = () => {
    setIsModalLaboratoryOpen(false);
  };

  useEffect(() => {
    const sessionExpired = localStorage.getItem('session_expired');
    if (sessionExpired === 'true') {
      localStorage.setItem('session_expired', 'false');
      toast.error('Sessão expirada. Por favor, reconecte.');
    }
    const getUrlLogin = async () => {
      try {
        const loginGov = await api.get(`/auth/cidadao?nonce=${uuidv4()}`);
        setUrlGov(loginGov.data);
      } catch (error) {
        console.error('Ocorreu um erro inesperado: ', error);
      }
    };

    const setLocalStorage = () => {
      const data = querystring.parse(search.replace('?', ''));

      ['refresh_token', 'user', 'token'].forEach(key => {
        if (data[key]) {
          localStorage.setItem(key, data[key].toString());
        }
      });
      if (data.token) {
        window.location.href = '/dashboard';
      }
    };

    const fetchTemplateData = async () => {
      setLoading(true);
      try {
        const responseTemplate = await api.get('/Template');
        const responseLaboratory = await api.get(
          '/enterprise/laboratory/accredited'
        );

        const dataTemplate = responseTemplate.data.map(
          (item: IPropsTemplate) => ({
            ...item,
            key: item._id,
          })
        );

        const dataLaboratory = responseLaboratory.data.map(
          (item: IPropsTemplate) => ({
            ...item,
            key: item._id,
          })
        );

        setTemplateList(dataTemplate);
        setLaboratoryList(dataLaboratory);
      } catch (error) {
        console.error('Ocorreu um erro inesperado: ', error);
      } finally {
        setLoading(false);
      }
    };
    getUrlLogin();
    setLocalStorage();
    fetchTemplateData();
  }, []);

  async function handleShowLaboratoryModal(data: number) {
    setIsLoading(true);
    try {
      const laboratoryResponse = await api.get(
        `/enterprise/laboratory/scope/${data}`
      );
      setAcreditation(laboratoryResponse.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Ocorreu um erro inesperado: ', error);
    } finally {
      setIsLoading(false);
    }
  }

  function downloadFile(data: any) {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API}/enterprise/laboratory/scope/${data}/download`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      responseType: 'blob',
    })
      .then(({ data, headers }) => {
        const filename =
          headers['content-disposition']
            ?.split('filename=')[1]
            ?.replace(/"/g, '') || 'downloaded_file.xlsx';
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const link = Object.assign(document.createElement('a'), {
          href: url,
          download: filename,
        });
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        console.error('Erro ao baixar o arquivo:', error);
        toast.error('Não existe arquivo para download.');
      });
  }

  return (
    <>
      <LayoutAuthSeparations
        handleClickLaboratoryButton={showLaboratoryModal}
        handleClickTemplateButton={showTemplateModal}
        data={data}
      >
        <Content>
          <img src={Logo} alt="Logo iema" />
          <ButtonSignInGov urlGov={urlGov} />
        </Content>
      </LayoutAuthSeparations>
      <Drawer
        title="Lista de Templates"
        size="large"
        closeIcon={false}
        placement="left"
        onClose={handleCancelTemplateModal}
        open={isModalTemplateOpen}
        extra={<CloseOutlined onClick={handleCancelTemplateModal} />}
      >
        <ContentTable>
          {loading ? (
            <Spin tip="Carregando..." size="large">
              <div className="content" />
            </Spin>
          ) : (
            <Table
              pagination={false}
              columns={columnsTemplate}
              dataSource={templateList}
              expandable={{
                expandedRowRender: record => (
                  <p style={{ margin: 0 }}>{record.description}</p>
                ),
                rowExpandable: record => record.description !== '',
              }}
            />
          )}
        </ContentTable>
      </Drawer>
      <Drawer
        title="Lista de Laboratórios"
        size="large"
        closeIcon={false}
        placement="right"
        onClose={handleCancelLaboratoryModal}
        open={isModalLaboratoryOpen}
        extra={<CloseOutlined onClick={handleCancelLaboratoryModal} />}
      >
        <ContentTable>
          {loading ? (
            <Spin tip="Carregando..." size="large">
              <div className="content" />
            </Spin>
          ) : (
            <Table
              pagination={false}
              columns={columnsLaboratory(
                handleShowLaboratoryModal,
                downloadFile
              )}
              dataSource={laboratoryList}
            />
          )}
        </ContentTable>
      </Drawer>
      <Modal
        width={1000}
        footer={[]}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <ListAccreditation
          isLoading={isLoading}
          accreditation={accreditation}
        />
      </Modal>
    </>
  );
};

export default Login;
