import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  position: relative;
  display: flex;
  width: 50px;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

export const EmptyMessageContainer = styled.p`
  text-align: center;
  padding: 20px 0;
`;
