import React, { useEffect, useState, useContext } from 'react';
import { Alert, Badge, Dropdown, Modal, Space, Spin, Table } from 'antd';
import { BellOutlined, LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Container, EmptyMessageContainer } from './styled';
import api from '../../../services/api';
import { NotificationContext } from '../../../providers/NoticationContext-context';

type INotification = {
  created_at: string;
  delete: boolean;
  deleted_at: string | null;
  enterprise_id: string;
  id: string;
  message: string;
  object: string;
  schema_version: number;
  tenant_id: string;
  time: {
    hour: number;
    minute: number;
  };
  title: string;
  type: 'success' | 'error' | 'warning';
  updated_at: string;
  user_id: string;
  __v: number;
  _id: string;
};

const columns = [
  {
    title: 'Validador',
    dataIndex: 'provide',
    key: 'provide',
  },
  {
    title: 'Tabela',
    dataIndex: 'table',
    key: 'table',
  },
  {
    title: 'Coluna',
    dataIndex: 'col',
    key: 'col',
  },
  {
    title: 'Valor',
    dataIndex: 'value',
    key: 'value',
    render: (content: any[] | string) =>
      Array.isArray(content) ? (
        <div>
          {content?.map((item, index) => (
            <p key={index}>• {item}</p>
          ))}
        </div>
      ) : (
        <p>• {content}</p>
      ),
  },
  {
    title: 'Linha',
    dataIndex: 'line',
    key: 'line',
  },
  {
    title: 'Mensagem',
    dataIndex: 'message',
    key: 'message',
    width: '200px',
    columnWidth: '200px',
  },
];

export function Notification() {
  const { status, setStatus } = useContext(NotificationContext);
  const [itens, setItens] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentModal, setContentModal] = useState();
  const [loading, setLoading] = useState(false);

  const showModal = (item: any) => {
    if (item.message) {
      setContentModal(item.message.errors);
    }

    if (item.errors) {
      setContentModal(item.errors);
    }

    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const getApi = async () => {
      const responseMessage = await api.get(`/message`);
      const notification: any = [];
      setLoading(false);
      setStatus(true);
      if (responseMessage?.status <= 205) {
        await Promise.all(
          responseMessage.data?.map((item: INotification, key: number) => {
            let obj = {};
            if (item.type !== 'warning') {
              let time = item?.time?.hour === 0 ? '' : `${item.time.hour}h`;
              time +=
                item?.time?.minute === 0 ? '< 1m' : ` ${item.time.minute}m `;
              obj = {
                key,
                label: (
                  <Alert
                    message={`${item.title} | Tempo: ${time} | ${dayjs(
                      item.updated_at
                    ).format('DD/MM/YYYY HH:mm')}`}
                    description={item.message}
                    type={item.type}
                    showIcon
                  />
                ),
                onClick: () => {
                  if (item?.object !== '') {
                    showModal(JSON.parse(item.object));
                  }
                },
              };
            } else {
              setLoading(true);
              setStatus(false);
              obj = {
                key,
                label: (
                  <Alert
                    message={item.title}
                    description={item.message}
                    type={item.type}
                    showIcon
                    icon={
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    }
                  />
                ),
              };
            }
            notification.push(obj);
          })
        );
        setItens(notification);
      }
    };
    getApi();
    const interval = setInterval(async () => {
      await getApi();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const emptyMessage = (
    <EmptyMessageContainer>
      Não há notificações no momento.
    </EmptyMessageContainer>
  );

  return (
    <NotificationContext.Provider value={{ status }}>
      <Dropdown
        trigger={['click']}
        placement="bottomCenter"
        menu={{
          items:
            itens.length > 0
              ? itens
              : [{ key: 'no-notifications', label: emptyMessage }],
        }}
      >
        <Container>
          <Space size="large">
            <Badge dot={loading}>
              <BellOutlined style={{ fontSize: 24 }} />
            </Badge>
          </Space>
        </Container>
      </Dropdown>
      <Modal
        title="Erros"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="100%"
        style={{ maxWidth: 800, maxHeight: 450, overflow: 'auto' }}
      >
        {contentModal &&
          (Array.isArray(contentModal) ? (
            <Table
              dataSource={contentModal}
              columns={columns}
              style={{ overflow: 'auto' }}
            />
          ) : (
            <>{contentModal}</>
          ))}
      </Modal>
    </NotificationContext.Provider>
  );
}
