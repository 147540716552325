import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Space } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { IPropAcreditation, IPropsTemplate } from './types';
import { convertDate } from '../../../utils/dateConvert';
import { formaterCpfAndCnpj } from '../../../utils/cpfAndCnpjFormater';

type HandleClickShowModalType = (id: number) => void;
type DownloadFileType = (id: number) => void;

export const data = {
  title: 'Templates ',
  titleSecondary: 'Laboratórios cadastrados',
  content:
    'Acesse e faça o download dos templates em Excel para envio de dados ao IEMA.',
  contentSecondary:
    'Acesse para visualizar a lista de laboratórios com acreditação ISO 17025 cadastrados no IEMA.',
  action: 'Tenha acesso aos templates',
  actionSecondary: 'Tenha acesso aos Laboratórios',
};

export const columnsTemplate: ColumnsType<IPropsTemplate> = [
  {
    title: 'Nome',
    dataIndex: '_id',
    key: '_id',
    width: 300,
  },
  {
    title: 'Data',
    dataIndex: 'lastCreatedAt',
    key: 'lastCreatedAt',
    render: text => convertDate(text),
    width: 150,
    align: 'center',
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a
          href={`${process.env.REACT_APP_URL_API}/template/download/templates/${record.url}`}
          rel="noopener noreferrer"
          aria-label="Download"
        >
          <DownloadOutlined style={{ fontSize: 24 }} />
        </a>
      </Space>
    ),
    align: 'center',
    width: 150,
  },
];

export const columnsLaboratory = (
  handleClickShowModal: HandleClickShowModalType,
  downloadFile: DownloadFileType
): ColumnsType<IPropAcreditation> => {
  return [
    {
      title: 'Razão social',
      dataIndex: 'enterprise_name',
      key: 'enterprise_name',
    },
    {
      title: 'CNPJ/CPF',
      dataIndex: 'cpf_cnpj',
      key: 'cpf_cnpj',
      render: text => formaterCpfAndCnpj(text),
    },
    {
      title: 'Cidade / ES',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Ação',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <>
          <EyeOutlined
            title="Visualizar"
            style={{ fontSize: 24 }}
            onClick={() => handleClickShowModal(record.id)}
          />

          <DownloadOutlined
            title="Baixar"
            style={{ fontSize: 24, marginLeft: 10 }}
            onClick={() => downloadFile(record.id)}
          />
        </>
      ),
    },
  ];
};
