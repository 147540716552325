import React, { useEffect, useState, useContext } from 'react';
import {
  ArrowLeftOutlined,
  CaretRightOutlined,
  DownloadOutlined,
  HomeFilled,
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import {
  Collapse,
  Empty,
  Form,
  FormInstance,
  Space,
  Spin,
  Table,
  Upload,
  theme,
} from 'antd';
import axios from 'axios';
import { ColumnsType } from 'antd/es/table';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { NotificationContext } from '../../../../providers/NoticationContext-context';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import { Button } from '../../../../components/shered/Button';
import { Container, ContentButton, ContentCard, ContentUpload } from './styles';
import api from '../../../../services/api';
import {
  convertDate,
  formatDateToDateAndHour,
} from '../../../../utils/dateConvert';
import { Modal } from '../../../../components/shered/Modal';
import { ICycle, IpropsConditions } from './types';
import { Card } from '../../../../components/shered/Card';
import { validAccess } from '../../../../hooks/roles';

const { Panel } = Collapse;

interface IPropsCyles {
  key: React.Key;
  protocol: string;
  date: string;
  type: string;
  url: string;
}

function downloadFile(data: string) {
  const token = localStorage.getItem('token');
  axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_API}/file/download/others/${data}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', data);
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => {
      console.error('Ocorreu um erro ao baixar o arquivo:', error);
      toast.error('Erro ao baixar o arquivo.');
    });
}

const columns: ColumnsType<IPropsCyles> = [
  {
    title: 'Protocolo',
    dataIndex: 'protocol',
    key: 'protocol',
  },
  {
    title: 'Data Envio',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Ações',
    key: 'url',
    render: (_, record) => (
      <Space size="middle">
        <DownloadOutlined
          onClick={() => downloadFile(record.url)}
          style={{ fontSize: 24 }}
        />
      </Space>
    ),
    align: 'center',
    width: 150,
  },
];

export function CyclesList({ title }: TitleProps) {
  useDocumentTitle(title);
  const history = useHistory();
  const { status } = useContext(NotificationContext);
  const {
    conditionId,
    id,
    enterprise_id,
    process_id,
    licensing_number,
    enterprise_name,
    main,
  }: any = useParams();
  const { token } = theme.useToken();
  const [open, setOpen] = useState(false);
  const formRef = React.useRef<FormInstance>(null);
  const [fileMonitoring, setFileMonitoring] = useState<File | null>(null);
  const [fileAdditional, setFileAdditional] = useState<File[]>([]);
  const [cycles, setCycles] = useState<ICycle[]>([]);
  const [cycle, setCycle] = useState('');
  const [
    visibleCadastrarMonitoramento,
    setVisibleCadastrarMonitoramento,
  ] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<IPropsCyles[]>([]);
  const [conditionData, setConditionData] = useState<IpropsConditions | null>(
    null
  );
  const file_Size = 125829120; /* Just only NUMBER */

  const breadcrumb = [
    { label: <HomeFilled />, link: '/dashboard' },
    { label: 'Empreendimentos', link: '/enterprise' },
    { label: `${enterprise_name}` },
    {
      label: 'Processos',
      link: `/enterprise/${enterprise_id}/${enterprise_name}/processos`,
    },
    { label: `${window.atob(main)}` },
    {
      label: 'Licenças',
      link: `/enterprise/${enterprise_name}/${enterprise_id}/process/${process_id}/${main}/licencas`,
    },
    { label: `${window.atob(licensing_number)}` },
    {
      label: `Condicionantes`,
      link: `/enterprise/${enterprise_name}/${enterprise_id}/process/${process_id}/${main}/licencas/${licensing_number}/condicionantes`,
    },
    { label: `${window.atob(conditionId)}` },
    { label: 'Ciclos' },
  ];

  const buttons = [
    <Button
      onClick={() => history.goBack()}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Voltar
    </Button>,
  ];

  const onReset = () => {
    formRef.current?.resetFields();
    setFileMonitoring(null);
    setFileAdditional([]);
  };

  const handleCancel = () => {
    setOpen(false);
    setFileAdditional([]);
    setFileMonitoring(null);
  };

  const relaceNameType = (data: string) => {
    if (data === 'Monitoring') {
      return 'Monitoramento';
    }
    return 'Arquivo complementar';
  };

  const createFormatDataSource = async () => {
    const { data } = await api.get(`/file/listCycle/${conditionId}`);
    const dataSource: IPropsCyles[] = data
      ?.map((cycle: ICycle) =>
        cycle.files.map((file: any, index) => ({
          key: index,
          protocol: file.protocol,
          date: formatDateToDateAndHour(
            dayjs(String(file.date))
              .add(-3, 'hour')
              .format('YYYY-MM-DD HH:mm:ss')
          ),
          type: relaceNameType(file.type),
          url: file.url,
        }))
      )
      .flat();
    setDataSource(dataSource);
    setCycles(data);
  };

  const createFormData = async (aditionalFiles: string[], cycle: string) => {
    const formData = new FormData();
    if (fileMonitoring) formData.append('file', fileMonitoring);
    formData.append('cycle', cycle);
    formData.append('condition_code', conditionId);
    formData.append('files_id', aditionalFiles.toString());
    try {
      await api.post('/file/monitoring', formData);
      await createFormatDataSource();
      setLoading(false);
      toast.info(
        'Aguardando o processamento do arquivo. Confira a atualização no sino de notificações.'
      );
    } catch (err) {
      toast.error(err.data.message);
    }
  };

  const createFormDataAditionalFile = async (fileMonitoring: any) => {
    const formDataAditionalFile = new FormData();
    formDataAditionalFile.append('file', fileMonitoring);
    const response = await api.post('/file/upload', formDataAditionalFile);
    return response.data;
  };

  const onFinish = async (cycle: string) => {
    try {
      const fileAditionalIds: string[] = [];
      await Promise.all(
        fileAdditional.map(async item => {
          const id = await createFormDataAditionalFile(item);
          fileAditionalIds.push(id);
        })
      );

      await createFormData(fileAditionalIds, cycle);
      onReset();
      setFileMonitoring(null);
      setFileAdditional([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao enviar monitoramento');
      console.error(error);
    }
  };

  useEffect(() => {
    const AsyncApi = async () => {
      setVisibleCadastrarMonitoramento(
        await validAccess('Cadastrar monitoramento')
      );
      await api.post('/file/createCycle', { condition_code: conditionId });
      const responseCondition = await api.get(`/conditional/${id}`);
      setConditionData(responseCondition.data);
      await createFormatDataSource();
      setLoading(false);
    };
    AsyncApi();
  }, [status]);

  const handleOk = (cycle: string) => {
    setLoading(true);
    onFinish(cycle);
    setOpen(false);
    onReset();
  };

  const handleModal = () => {
    return (
      <Form
        ref={formRef}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={error => {
          console.log({ error });
        }}
      >
        <Form.Item
          name="fileMonitoring"
          valuePropName="fileList"
          getValueFromEvent={event => {
            return event?.fileList;
          }}
        >
          <ContentUpload>
            <Upload
              showUploadList={false}
              maxCount={1}
              beforeUpload={file => {
                return new Promise((resolve, reject) => {
                  if (file && file.size > file_Size) {
                    reject(new Error('tamanho excedido'));
                    toast.error('Tamanho de arquivo excedido');
                  } else {
                    setFileMonitoring(file);
                    resolve('Sucess');
                  }
                });
              }}
            >
              <Button block>Envio de Monitoramento</Button>
              {fileMonitoring && <span>{fileMonitoring?.name}</span>}
            </Upload>
          </ContentUpload>
        </Form.Item>
        <Form.Item
          name="fileAdditional"
          valuePropName="fileList"
          getValueFromEvent={event => {
            return console.log('event', event);
          }}
        >
          <ContentUpload>
            <Upload
              showUploadList={false}
              style={{ display: 'flex' }}
              multiple
              accept=".doc,.docx,.pdf,.jpg,.jpeg,.png"
              beforeUpload={file => {
                return new Promise((resolve, reject) => {
                  if (file && file.size > file_Size) {
                    reject(new Error('tamanho excedido'));
                    toast.error('Tamanho de arquivo excedido');
                  } else {
                    setFileAdditional(prevState => [...prevState, file]);
                    resolve('Sucess');
                  }
                });
              }}
            >
              <Button block>Envio de arquivo complementar</Button>
              {fileAdditional?.map((item, index) => (
                <React.Fragment key={index}>
                  <p>{item.name}</p>
                </React.Fragment>
              ))}
            </Upload>
          </ContentUpload>
        </Form.Item>
      </Form>
    );
  };

  const showModal = (cycle: any) => {
    setOpen(true);
    setCycle(cycle);
  };

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Envie e visualize seus monitoramentos"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <Container>
        {loading ? (
          <Spin tip="Enviando..." size="large">
            <div className="content" />
          </Spin>
        ) : (
          <>
            <ContentCard>
              <Card style={{ width: '100%' }}>
                <p>
                  <b>Tipo de Licença:</b> {conditionData?.license_type ?? '--'}
                </p>
                <p>
                  <b>Número da Condicionante:</b>{' '}
                  {conditionData?.condition_number ?? '--'}
                </p>
                <p>
                  <b>Versão:</b> {conditionData?.version ?? '--'}
                </p>
                <p>
                  <b>Matriz de monitoramento:</b>{' '}
                  {conditionData?.monitoring_matrix ?? '--'}
                </p>
                <p>
                  <b>Frequência de envio(dia):</b>{' '}
                  {conditionData?.delivery_frequency ?? '--'}
                </p>
                <p>
                  <b>Data de Início:</b>{' '}
                  {convertDate(conditionData?.start_date) || '--'}
                </p>
                <p>
                  <b>Data de Fim:</b>{' '}
                  {convertDate(conditionData?.end_date) || '--'}
                </p>
                <p>
                  <b>Comentário:</b> {conditionData?.comment ?? '--'}
                </p>
              </Card>
            </ContentCard>
            {cycles.length ? (
              cycles.map((item: ICycle, index: number) => (
                <Collapse
                  key={index}
                  accordion
                  size="large"
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  style={{ background: token.colorBgContainer }}
                >
                  <Panel
                    header={`Ciclo ${item.cycle} - ${convertDate(
                      item.start_date
                    )} a ${convertDate(item.end_date)}`}
                    key={index}
                  >
                    <ContentButton>
                      {visibleCadastrarMonitoramento && (
                        <Button
                          type="primary"
                          onClick={() => showModal(item.cycle)}
                        >
                          Novo arquivo
                        </Button>
                      )}
                    </ContentButton>
                    <Table
                      columns={columns}
                      dataSource={dataSource.filter(data =>
                        item.files.some(file => file.protocol === data.protocol)
                      )}
                      pagination={false}
                    />
                  </Panel>
                </Collapse>
              ))
            ) : (
              <Empty description="Ainda não possui ciclos" />
            )}
          </>
        )}
        <Modal
          centered
          open={open}
          title="Envio de monitoramento"
          onCancel={handleCancel}
          footer={[
            <Button htmlType="button" danger onClick={onReset}>
              Limpar
            </Button>,
            <Button
              style={{ width: '6rem' }}
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => handleOk(cycle)}
            >
              Enviar
            </Button>,
          ]}
        >
          {handleModal()}
        </Modal>
      </Container>
    </LayoutAppCMS>
  );
}
