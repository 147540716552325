import React, { useEffect, useState } from 'react';
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  HomeFilled,
  SnippetsOutlined,
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { Form, FormInstance, Space, Spin, Table, Upload } from 'antd';
import { ColumnsType } from 'antd/es/table';
import axios from 'axios';
import { toast } from 'react-toastify';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import { Button } from '../../../../components/shered/Button';
import { ContentTable, ContentUpload } from './styles';
import api from '../../../../services/api';
import { Modal } from '../../../../components/shered/Modal';
import { convertDate } from '../../../../utils/dateConvert';
import { ListFile } from './listFile';
import { treatmentError } from '../../../../hooks/erros';

interface IPropsWellSoundings {
  date: string;
  folder: string;
  id: string;
  name: string;
  protocol: string;
  type: string;
  url: string;
}

export function PointCharacterization({ title }: TitleProps) {
  useDocumentTitle(title);
  const { enterprise_id, enterprise_name }: any = useParams();
  const formRef = React.useRef<FormInstance>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accreditation, setAcreditation] = useState<any>();
  const [wellSoundings, setWellSoundings] = useState([]);
  const [fileMonitoring, setFileMonitoring] = useState<File | null>(null);
  const [fileAdditional, setFileAdditional] = useState<File[]>([]);
  const [disable, setDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [send, setSend] = useState(false);
  const file_Size = 125829120; /* Just only NUMBER */

  function downloadFile(file: string) {
    const token = localStorage.getItem('token');
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API}/file/download/others/${file}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error('Ocorreu um erro ao baixar o arquivo:', error);
        toast.error('Não existe arquivo para download.');
      });
  }

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Empreendimentos',
      link: '/enterprise',
    },
    {
      label: `${enterprise_name}`,
    },
    {
      label: 'Pontos de monitoramento',
      link: '/enterprise',
    },
    {
      label: 'Caracterização de ponto',
    },
  ];

  async function handleClickShowModal(data: any) {
    setLoading(true);
    try {
      const laboratoryResponse = await api.get(
        `/file/wells-soundings-other-files/${data}`
      );
      if (
        laboratoryResponse.status === 401 ||
        laboratoryResponse.status === 404
      ) {
        toast.error('Não existe arquivos complementares.');
      } else {
        setAcreditation(laboratoryResponse.data);
        setIsModalOpen(true);
      }
    } catch (error) {
      toast.error(`Não existe arquivo.`, error);
    } finally {
      setLoading(false);
    }
  }

  const columns: ColumnsType<IPropsWellSoundings> = [
    {
      title: 'Protocolo de Envio',
      dataIndex: 'protocol',
    },
    {
      title: 'Data de criação',
      dataIndex: 'date',
      key: 'date',
      render: text => convertDate(text),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <>
          <Space align="center" size="large">
            <DownloadOutlined
              title="Download"
              onClick={() => downloadFile(record.url)}
              style={{ fontSize: 24 }}
            />
            <SnippetsOutlined
              title="Complementar"
              onClick={() => handleClickShowModal(record.id)}
              style={{ fontSize: 24 }}
            />
          </Space>
        </>
      ),
      align: 'center',
    },
  ];

  useEffect(() => {
    const AsyncApi = async () => {
      const responseWellSoundings = await api.get('/file/list/WellSoundings');
      setWellSoundings(responseWellSoundings.data);
      setLoading(false);
      setSend(false);
    };
    AsyncApi();
  }, [send]);

  const showModal = () => {
    setOpen(true);
  };

  const buttons = [
    <Button
      icon={<ArrowLeftOutlined />}
      size="large"
      onClick={() => history.goBack()}
    >
      Voltar
    </Button>,
    <Button onClick={showModal} size="large">
      Nova Importação
    </Button>,
  ];

  const onReset = () => {
    formRef.current?.resetFields();
    setFileMonitoring(null);
    setFileAdditional([]);
    setDisable(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setFileAdditional([]);
    setFileMonitoring(null);
  };

  const createFormData = async (aditionalFiles: string[]) => {
    const formData = new FormData();
    if (fileMonitoring) formData.append('file', fileMonitoring);
    formData.append('condition_code', enterprise_id);
    formData.append('files_id', aditionalFiles.toString());
    const wellSoudingsResp = await api.post('/file/wells-soundings', formData);
    if (wellSoudingsResp.status === 200) {
      setLoading(false);
      toast.success('Enviado com sucesso');
      setSend(true);
    } else {
      const error = treatmentError(wellSoudingsResp.data);
      toast.error(error);
      setLoading(false);
    }
  };

  const createFormDataAditionalFile = async (fileMonitoring: any) => {
    const formDataAditionalFile = new FormData();
    formDataAditionalFile.append('file', fileMonitoring);
    const response = await api.post('/file/upload', formDataAditionalFile);
    return response.data;
  };

  const onFinish = async () => {
    try {
      const fileAditionalIds: string[] = [];
      await Promise.all(
        fileAdditional.map(async item => {
          const id = await createFormDataAditionalFile(item);
          fileAditionalIds.push(id);
        })
      );
      await createFormData(fileAditionalIds);
      onReset();
      setFileMonitoring(null);
      setFileAdditional([]);
      toast.success('Caracterização enviada com sucesso');
    } catch (error) {
      const errorMessage = treatmentError(error);
      toast.error(`${errorMessage}`);
    }
  };

  const handleOk = () => {
    onFinish();
    setLoading(true);
    setOpen(false);
    onReset();
  };

  return (
    <>
      <LayoutAppCMS>
        <PageDescription
          description="Veja as caracterizações validadas e adicione novas caracterizações."
          title={title}
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <ContentTable>
          <Spin spinning={loading}>
            <h1>Arquivos de caracterização</h1>
            <Table
              pagination={false}
              columns={columns}
              dataSource={wellSoundings}
              style={{ marginTop: 10 }}
            />
            <Modal
              centered
              open={open}
              title="Envio de caracterização"
              onCancel={handleCancel}
              footer={[
                <Button htmlType="button" danger onClick={onReset}>
                  Limpar
                </Button>,
                <Button
                  style={{ width: '6rem' }}
                  disabled={disable}
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  onClick={() => handleOk()}
                >
                  Enviar
                </Button>,
              ]}
            >
              <Form
                ref={formRef}
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={error => {
                  console.log({ error });
                }}
              >
                <Form.Item
                  name="fileMonitoring"
                  valuePropName="fileList"
                  getValueFromEvent={event => {
                    return event?.fileList;
                  }}
                >
                  <ContentUpload>
                    <Upload
                      showUploadList={false}
                      maxCount={1}
                      beforeUpload={file => {
                        return new Promise((resolve, reject) => {
                          if (file && file.size > file_Size) {
                            reject(new Error('tamanho excedido'));
                            toast.error('Tamanho de arquivo excedido');
                          } else {
                            setFileMonitoring(file);
                            setDisable(false);
                            resolve('Sucess');
                          }
                        });
                      }}
                    >
                      <Button block>Upload caracterização</Button>
                      {fileMonitoring && <span>{fileMonitoring?.name}</span>}
                    </Upload>
                  </ContentUpload>
                </Form.Item>
                <Form.Item
                  name="fileAdditional"
                  valuePropName="fileList"
                  getValueFromEvent={event => {
                    return console.log('event', event);
                  }}
                >
                  <ContentUpload>
                    <Upload
                      showUploadList={false}
                      style={{ display: 'flex' }}
                      multiple
                      accept=".doc,.docx,.pdf,.jpg,.jpeg,.png"
                      beforeUpload={file => {
                        return new Promise((resolve, reject) => {
                          if (file && file.size > file_Size) {
                            reject(new Error('tamanho excedido'));
                            toast.error('Tamanho de arquivo excedido');
                          } else {
                            setFileAdditional(prevState => [
                              ...prevState,
                              file,
                            ]);
                            resolve('Sucess');
                          }
                        });
                      }}
                    >
                      <Button block>Upload arquivo complementar</Button>
                      {fileAdditional?.map((item, index) => (
                        <React.Fragment key={index}>
                          <p>{item.name}</p>
                        </React.Fragment>
                      ))}
                    </Upload>
                  </ContentUpload>
                </Form.Item>
              </Form>
            </Modal>
          </Spin>
        </ContentTable>
      </LayoutAppCMS>
      <Modal
        width={1000}
        footer={[]}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <ListFile isLoading={loading} accreditation={accreditation} />
      </Modal>
    </>
  );
}
